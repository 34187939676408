import React from 'react';
import { Router } from "@reach/router";
import ItemComponent from "../components/item-component"

const Item = ({ location }) => {
  return (
    <>
      <Router basepath="/items">
        <ItemComponent path="/:itemId" location={location}/>
      </Router>
    </>
  )
}

export default Item