import React from "react"
import gql from 'graphql-tag';

import { useQuery } from '@apollo/react-hooks';
// import { rhythm } from "../utils/typography"
import ItemPageLayout from './item-page-layout'

const APOLLO_QUERY = gql`
  query($itemId: ID!) {
    getItem(itemId: $itemId) {
      itemId
      name
      nameOthers
      slug
      description
      cardImage {
        thumbUrl
        cardUrl
        dynamicUrl
      }
      cardLocation
      locations {
        locationId
        name
        nameDisplay
        country
      }
      tags {
        tagId
        name
        displayOrder
      }
      links {
        linkId
        type
        url
      }
      mapLinks {
        label
        link
      }
    }
  }
`

const ItemComponent = (prop) => {
  const { data } = useQuery(APOLLO_QUERY, { variables: { itemId: prop.itemId } });

  if (data && data.getItem) {
    const item = data.getItem
    return (
      <ItemPageLayout title={prop.title} location={prop.location} item={item}/>
    );
  } else {
    return (<div>...</div>);
  }
}

export default ItemComponent