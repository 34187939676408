import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { rhythm } from "../utils/typography"

const ItemTags = ({ tags }) => {
  if (tags && tags.length > 0) {
    return (
      <div>
        <h4>Tags</h4>
        {tags.map(({ tagId, name }) => (
          <div
            style={{ display: "inline-block", margin: "0.5em 0.8em 0.8em 0em" }}
            key={tagId}
          >
            <span
              style={{
                padding: "0.6em 1em",
                fontSize: "0.9em",
                fontStyle: "normal",
                whiteSpace: "nowrap",
                borderRadius: "2em",
                backgroundColor: "#ddd",
              }}
            >
              {name}
            </span>
          </div>
        ))}
      </div>
    )
  } else {
    return <div></div>
  }
}

const ItemLinks = ({ links }) => {
  if (links && links.length > 0) {
    return (
      <div>
        <h4>Links</h4>
        {links.map(({ url, type }) => (
          <div
            style={{ display: "inline-block", margin: "0.5em 0.8em 0.8em 0em" }}
            key={type + url}
          >
            <a
              style={{
                color: "black",
                fontSize: "0.9em",
                textDecoration: "none",
              }}
              href={url}
            >
              <span
                style={{
                  padding: "0.6em 1em",
                  fontSize: "0.9em",
                  fontStyle: "normal",
                  whiteSpace: "nowrap",
                  borderRadius: "2em",
                  backgroundColor: "#ddd",
                }}
              >
                {type}
              </span>
            </a>
          </div>
        ))}
      </div>
    )
  } else {
    return <div></div>
  }
}

const ItemMapLinks = ({ links }) => {
  if (links && links.length > 0) {
    return (
      <div>
        <h4>Map Links</h4>
        {links.map(({ link, label }) => (
          <div
            style={{ display: "inline-block", margin: "0.5em 0.8em 0.8em 0em" }}
            key={label + link}
          >
            <a
              style={{
                color: "black",
                fontSize: "0.9em",
                textDecoration: "none",
              }}
              href={link}
            >
              <span
                style={{
                  padding: "0.6em 1em",
                  fontSize: "0.9em",
                  fontStyle: "normal",
                  whiteSpace: "nowrap",
                  borderRadius: "2em",
                  backgroundColor: "#ddd",
                }}
              >
                {label}
              </span>
            </a>
          </div>
        ))}
      </div>
    )
  } else {
    return <div></div>
  }
}

const ItemPageLayout = ({ location, item }) => {
  let name = item.name ?? ""
  let nameLocal = item.nameLocal
  let locations =
    item.locations
      ?.map(l => [l.nameDisplay, l.country].join(", "))
      .join("\n") ?? ""
  let description = item.description ?? ""
  let imageUrl =
    item.cardImage?.cardUrl ?? "https://via.placeholder.com/300x200"

  const webLink = `https://tictic.app/item/${item.itemId}`
  const fdLink = `https://tictic.page.link/?link=${webLink}&apn=co.tictic.tictic&isi=1437805697&ibi=co.tictic.tictic`

  return (
    <Layout location={location} title={name}>
      <Seo title={name} description={description} />
      <hr
        style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(1),
        }}
      />
      <article>
        <header>
          <div>
            <img
              style={{ width: "100%", borderRadius: "3%" }}
              src={imageUrl}
              alt={name}
            />
          </div>
          <h3
            style={{
              marginTop: rhythm(0),
              marginBottom: rhythm(1),
            }}
          >
            {name}
          </h3>
        </header>
      </article>
      <div>
        {nameLocal && <p>Local Name: {nameLocal}</p>}
        <p>{locations}</p>
        <p style={{ color: "grey" }}>{description}</p>
      </div>

      <br />
      <ItemLinks links={item.links} />
      <br />
      <ItemMapLinks links={item.mapLinks} />
      <br />
      <ItemTags tags={item.tags} />
      <br />
      <a href={fdLink}>Open in App</a>
    </Layout>
  )
}

export default ItemPageLayout
